#products_module.cms_list {
	.cms_list_item.left {
		width: 50%;
		padding-right: 10px;
	}

	.cms_list_item.right {
		width: 50%;
		padding-left: 10px;
	}

	.cms_list_item {
		.cms_title {
			margin: 0px;
			padding: 7px 0px 0px 0px;
		}

		.image {
			padding: 0px 0px 7px 7px;
		}

		.tile_description {
			padding: 10px 0px 7px 0px;

			.abstract {
				padding: 0px 0px 7px 0px;
			}

			p {
				margin: 0px;
			}
		}

		.description {
			padding: 7px 0px 0px 0px;
		}

		.cms_date {
			padding: 7px 15px 0px 0px;
			white-space: nowrap;
		}
	}

	.cms_item_divider {
		height: 0px;
		margin-top: 10px;
		margin-bottom: 10px;
		border-top: 1px solid #e1e3e0;
	}
}

#products_module.cms_entity {
	.cms_item_divider {
		height: 0px;
		margin-top: 10px;
		margin-bottom: 10px;
		border-top: 1px solid #e1e3e0;
	}

	.cms_content {
		.image {
			margin: 0px 0px 10px 10px;
		}

		ul {
			overflow: auto;
		}

		ol {
			overflow: auto;
		}
	}
}
